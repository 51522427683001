@charset "US-ASCII";
@import "http://fonts.googleapis.com/css?family=Lato:300,400,700";
.openpayContainer{
  border-radius: 10px;
  overflow: hidden;
}
.openpayContainer * {
  color: #444;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
}
.openpayContainer ::-webkit-input-placeholder {
  font-style: italic;
}
.openpayContainer :-moz-placeholder {
  font-style: italic;
}
.openpayContainer ::-moz-placeholder {
  font-style: italic;
}
.openpayContainer :-ms-input-placeholder {
  font-style: italic;
}

.openpayContainer strong {
  font-weight: 700;
}
.openpayContainer a {
  cursor: pointer;
  display: block;
  text-decoration: none;
}
.openpayContainer a.button {
  border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  padding: 12px 0;
  width: 100%;
  display: table;
  background: #2347AD;
}
.openpayContainer a.button i {
  margin-right: 10px;
}
.openpayContainer a.button.disabled {
  background: none repeat scroll 0 0 #ccc;
  cursor: default;
}
.openpayContainer .bkng-tb-cntnt {
  float: left;
  width: 800px;
}
.openpayContainer .bkng-tb-cntnt a.button {
  color: #fff;
  float: right;
  font-size: 18px;
  padding: 5px 20px;
  width: auto;
}
.openpayContainer .bkng-tb-cntnt a.button.o {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  color: #2347AD;
  border: 1px solid #2347AD;
}
.openpayContainer .bkng-tb-cntnt a.button i {
  color: #fff;
}
.openpayContainer .bkng-tb-cntnt a.button.o i {
  color: #2347AD;
}
.openpayContainer .bkng-tb-cntnt a.button.right i {
  float: right;
  margin: 2px 0 0 10px;
}
.openpayContainer .bkng-tb-cntnt a.button.left {
  float: left;
}
.openpayContainer .bkng-tb-cntnt a.button.disabled.o {
  border-color: #ccc;
  color: #ccc;
}
.openpayContainer .bkng-tb-cntnt a.button.disabled.o i {
  color: #ccc;
}
.openpayContainer .pymnts {
  float: left;
  width: 800px;
}
.openpayContainer .pymnts * {
  float: left;
}

.openpayContainer .sctn-row {
  margin-bottom: 35px;
  width: 800px;
}
.openpayContainer .sctn-col {
  width: 375px;
}
.openpayContainer .sctn-col.l {
  width: 425px;
}
.openpayContainer .sctn-col input {
  border: 1px solid #ccc;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 12px;
  width: 333px;
}
.openpayContainer .sctn-col label {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
  width: 100%;
}
.openpayContainer .sctn-col.x3 {
  width: 300px;
}
.openpayContainer .sctn-col.x3.last {
  width: 200px;
}
.openpayContainer .sctn-col.x3 input {
  width: 210px;
}
.openpayContainer .sctn-col.x3 a {
  float: right;
}
.openpayContainer .pymnts-sctn {
  width: 800px;
}
.openpayContainer .pymnt-itm {
  margin: 0 0 3px;
  width: 800px;
}
.openpayContainer .pymnt-itm h2 {
  background-color: #e9e9e9;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 28px 0 28px 20px;
  width: 780px;
}
.openpayContainer .pymnt-itm.active h2 {
  background-color: #2347AD;
  color: #fff;
  cursor: default;
  width: 100%;
}
.openpayContainer .pymnt-itm div.pymnt-cntnt {
  display: none;
}
.openpayContainer .pymnt-itm.active div.pymnt-cntnt {
  background-color: #f7f7f7;
  display: block;
  padding: 0 0 30px;
  width: 100%;
}

.openpayContainer .pymnt-cntnt div.sctn-row {
  margin: 20px 30px 0;
  width: 740px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col {
  width: 345px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.l {
  width: 395px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col input {
  width: 303px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.half {
  width: 155px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.half.l {
  float: left;
  width: 190px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.half input {
  width: 113px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.cvv {
  background-image: url("./assets/cvv.png");
  background-position: 156px center;
  background-repeat: no-repeat;
  padding-bottom: 30px;
}
.openpayContainer .pymnt-cntnt div.sctn-row div.sctn-col.cvv div.sctn-col.half input {
  width: 110px;
}
.openpayContainer .openpay {
  float: right;
  height: 60px;
  margin: 10px 30px 0 0;
  width: 435px;
}
.openpayContainer .openpay div.logo {
  background-image: url("./assets/openpay.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  border-right: 1px solid #ccc;
  font-size: 12px;
  font-weight: 400;
  height: 65px;
  padding: 15px 20px 0 0;
}
.openpayContainer .openpay div.shield {
  background-image: url("./assets/security.png");
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 12px;
  font-weight: 400;
  margin-left: 20px;
  padding: 20px 0 0 40px;
  width: 200px;
}
.openpayContainer .card-expl {
  float: left;
  height: 80px;
  margin: 20px 0;
  width: 800px;
}
.openpayContainer .card-expl div {
  background-position: left 45px;
  background-repeat: no-repeat;
  height: 70px;
  padding-top: 10px;
}
.openpayContainer .card-expl div.debit {
  background-image: url("./assets/cards2.png");
  margin-left: 20px;
  width: 540px;
}
.openpayContainer .card-expl div.credit {
  background-image: url("./assets/cards1.png");
  border-right: 1px solid #ccc;
  margin-left: 30px;
  width: 209px;
}
.openpayContainer .card-expl h4 {
  font-weight: 400;
  margin: 0;
}