body {
  background-color: #fff;
  padding: 0 !important;
}

#root {
  min-height: 100vh;
  background-color: #f4f4f4;
}

.fw-bold {
  font-weight: 700 !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  min-height: 100vh;
}

small {
  font-size: 16px;
  color: #393d3f;
}

input::placeholder {
  color: #a8a8a8;
  opacity: 1;
}

label.Mui-error {
  color: #f90d38 !important;
  opacity: 1 !important;
}

.pointer {
  cursor: pointer;
}

.strong-password {
  color: #69c63d !important;
}

.validated-text {
  font-size: 12px !important;
}

.validated-text > .MuiTypography-body1 {
  font-size: 12px !important;
}

.NewPassword-listItem {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.p-2 {
  padding: 8px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.text-right {
  text-align: end !important;
}

.primary-text {
  color: #5c7bf0 !important;
}

.primary-text > span > svg {
  color: #5c7bf0 !important;
}

.small-title {
  font-family: "Barlow", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.small-text {
  font-family: "Barlow", sans-serif !important;
  font-size: 10.24px !important;
  line-height: 12px !important;
  color: #5c7bf0;
}

.MuiFormLabel-root {
  font-family: "Open Sans", serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.MuiLinearProgress-colorPrimary {
  background: rgba(33, 70, 183, 0.1) !important;
}

.MuiAlert-root {
  margin-bottom: 10px;
}

.terms-text {
  margin-left: -18px !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.button-plus {
  font-size: 12px !important;
}

.react-calendar__tile--active {
  border-radius: 100px;
  height: 50px;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar {
  border: none !important;
}

.button-border {
  border: 1px solid #5c7bf0 !important;
  color: #5c7bf0 !important;
}

.button-border-materiality {
  border: 2px solid white !important;
  border-radius: 5px !important;
  padding: 0 !important;
  color: white !important;
}

.font-color {
  color: #5c7bf0 !important;
}

.font-withe {
  color: #e0e0e0 !important;
}

.mini-button-size {
  width: 35px !important;
  height: 35px !important;
}

.modal {
  align-items: center;
  align-content: center;
}

.modal-body {
  background: white;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 25px;
}

.modal-size {
  height: 90% !important;
}

.react-calendar__tile--active {
  color: white !important;
}

.highlight-calendar {
  background-image: url("./assets/img/circle.png") !important;
  background-repeat: no-repeat !important;
  background-position: 75% 25% !important;
  background-size: 5px 5px !important;
}

.primary-color-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.primary-color-bar > .MuiLinearProgress-barColorPrimary {
  background: #5c7bf0 !important;
}

.form-size > div > div {
  max-width: 670px !important;
}

/* Used to blur the main content */
.main-content-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
  padding: 6px;
}

.text-success {
  color: #69c63d !important;
}
.text-error {
  color: #f90d38 !important;
}
.text-warning {
  color: #f90d38 !important;
}
.text-info {
  color: #5c7bf0 !important;
}

/* OTP Styles */
.otp-inputs div {
  display: flex;
  justify-content: space-between;
  min-width: 10rem;
}
.otp-inputs div input {
  border: 2px solid;
  border-radius: 0.2em;
  width: 1.5em !important;
  font-size: 2.2em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}